import React from 'react'
import Gallery from '../Gallery'
import { food } from './Activities'

export default function Food() {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Help to Poor</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood.jpg?alt=media&token=9f37a225-7834-40a6-9912-ee6b869c79a6' alt="" />
                    <h4 className='text-center'>"Bhikṣāṃ dēhi kṛpāvalambanakarī mātānnapūrṇēśvarī"</h4>
                    <div className='p-2'>
                        <h5>H.H.Sri Srihari Theertha said:</h5>
                        <p>
                            "Donating to the needy is a great way to improve the conditions in your neighborhood or community. Donating food to the worthy people or organizations helps counter poverty, hunger and at the same time, it can improve harmony, friendliness, and trust among residents."
                        </p>
                        <p>We saw in lockdown many poor people are unable to feed themselves as many depend on daily wages.</p>
                        <p> "So, we have decided to feed those poor people during the lockdown. We are also provided free food at various hospitals in Nellore". we provided asramam-cooked food on daily basis nearly about 400 cooked packets are being distributed to the poor people. we reguraly provide AnnaDanam at our various Ashram Branches.
                        </p>
                    </div>
                </div>
                <div>
                    <Gallery gallery={food} />
                </div>
            </div>
        </>
    )
}
