import React from 'react'
import Gallery from '../Gallery'
import { scholar } from './Activities'

export default function Scholar() {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Sri Satyananda Educational AID(Scholarships)</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FScholarship%2Fschol-1.jpg?alt=media&token=7d5c3a0f-bbe2-4786-9223-316c7d1d63f6' alt="" />
                    <div className='p-2'>
                        <p> We provides qualified applicants with financial support while seeking a college degree or some type of vocational training. An educational trust fund of this type may be structured to provide assistance to individuals coming from families within a certain income bracket and to support students who wish to enter a job field that is highly specialized. </p>

                        <p> Special scholarship we may provide assistance in other situations, such as providing single parents with the resources to obtain a degree and increase their ability to support their children. The assistance offered by our  fund may be structured to match grants or scholarships from other organizations, up to a certain figure, or provide the sole means of supporting the student in the pursuit of his or her education. </p>
                    </div>
                </div>
                <div>
                    <Gallery gallery={scholar} />
                </div>
            </div>
        </>
    )
}
