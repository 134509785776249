import React from 'react'
import { Link } from 'react-router-dom'
import {FaFacebook, FaInbox, FaPhone} from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'

export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container py-3 py-sm-5 justify-content-evenly">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <h5 className='text-danger text-decoration-underline'>Quick links</h5>
                            <ul className="list-unstyled">
                                <li><Link className='link1' to="/Help-to-Poor">Help to Poor</Link></li>
                                <li><Link className="link1" to="/Medical-Camps">Medical Camps</Link></li>
                                <li><Link className="link1" to="/Yoga-Meditation">Yoga &Meditation</Link></li>
                                <li><Link className="link1" to="/Goseva">Goshala</Link></li>
                                <li><Link className="link1" to="/Oldage-Home">Oldage Home</Link></li>
                                <li><Link className="link1" to="/Scholarships">Scholerships</Link></li>
                                <li><Link className="link1" to="/Personality-Development">Personality Development</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <h5 className='text-danger text-decoration-underline'>Information</h5>
                            <ul className="list-unstyled">
                                <li><Link className='link1' to="/About">About us</Link></li>
                                <li><Link className='link1' to="/Donate">Donate Us</Link></li>
                                <li><Link className='link1' to="/Contact">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <h5 className='text-danger text-decoration-underline'>Follow us</h5>
                            <ul className="list-unstyled">
                                <li><FaFacebook className='text-primary me-1' /> <a className='link1' href="https://www.facebook.com/groups/994050044662329/?ref=share" target='blank'> Facebook</a></li>
                                <li><FaInstagram className='text-danger me-1' /> <a className='link1' href="#0"> Instagram</a></li>
                                <li><FaTwitter className='text-info me-1' /> <a className='link1' href="#0"> Twitter</a></li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <h5 className='text-decoration-underline text-danger'>Our location</h5>
                            <div>
                                <h5 className='text-success'>Sri Satynanda Maharshi Geetha Satsangam</h5>
                                <p>Old Bowenpally <br />
                                Secunderabad <br />
                                Pincode: 500011 <br /> 
                                Telangana </p>
                                <FaPhone className='text-success me-2' />
                                <a className='link1' href="tel:+919441400994"> +91 9441400994</a>
                                <br />
                                <FaInbox className='text-success me-2' />
                                <a className='link1' href="mailto:bssmtrust4@gmail.com"> bssmtrust4@gmail.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-sm-6 text-center col-lg-12">
                            <ul className="list-inline">
                                <li className="list-inline-item text-secondary">&copy; 2021 Bhagavan Sri Satyananda Maharshi Charitable Trust</li> <br />
                                <li className="list-inline-item text-secondary"> All rights reserved.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
