import React from 'react'
import { Link } from 'react-router-dom'
import header from '../Assets/Header.png'

export default function Navbar() {
    return (
        <>
            <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white">
                <div className="container-fluid">
                    <Link className="navbar-brand text-danger" to="/">
                        <img src={header} width='260px' className='img-fluid p-0' alt="" />
                    </Link>
                    <button className="navbar-toggler p-1 text-warning bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/About'>About Us</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                   Our Activities
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/Help-to-Poor">Help to Poor</Link></li>
                                    <li><Link className="dropdown-item" to="/Medical-Camps">Medical Camps</Link></li>
                                    <li><Link className="dropdown-item" to="/Yoga-Meditation">Yoga &Meditation</Link></li>
                                    <li><Link className="dropdown-item" to="/Goseva">Goshala</Link></li>
                                    <li><Link className="dropdown-item" to="/Oldage-Home">Oldage Home</Link></li>
                                    <li><Link className="dropdown-item" to="/Scholarships">Scholerships</Link></li>
                                    <li><Link className="dropdown-item" to="/Personality-Development">Personality Development</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/Donate'>Donate Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/Contact'>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
