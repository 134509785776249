import React from 'react'

export default function Contact() {
    return (
        <>
            <div className="container-fluid">
                <h2 className='text-center m-2 text-decoration-underline'>Contact</h2>
                <div className='row row-cols-1 row-cols-md-2 p-2 g-3'>
                    <div className='col'>
                        <h3 className='text-decoration-underline'>Address:</h3>
                        <div className='text-dark'>
                            <h5 className='text-danger'>Sri Satynanda Maharshi Geetha Satsangam</h5>
                            Old Bowelpally, Secunderabad <br />
                            Pincode: 500011 <br />
                            Telangana <br />
                            Contact: 9441400994
                        </div>
                    </div>
                    <div className='col p-2'>
                        <h3 className='text-decoration-underline'>Route Map:</h3>
                        <iframe className='embed-responsive' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15222.268448383671!2d78.4754781!3d17.4804214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x27583369797ec88a!2sSri%20Satyananda%20Maharshi%20GEETA%20SATSANG!5e0!3m2!1sen!2sin!4v1631789394442!5m2!1sen!2sin" title='BSSM Trust' loading="lazy"></iframe>
                    </div>
                </div>
            </div>
            
            
        </>
    )
}
