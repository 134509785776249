import React from 'react'

export default function Reviews() {
    return (
        <>
            <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-inner text-center">
                    <div className="carousel-item active" data-bs-interval="2000">
                        <div className="card border-0 py-5">
                            <div className="block">
                                <div className="block">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Reviews%2FSaikrishna.jpg?alt=media&token=7c39efcf-2273-4853-9489-6a709304e367' className="img-fluid rounded-circle" width='150px' alt="..."/>
                                </div>
                                <div className="block">
                                    <div className="card-body">
                                        <h5 className="card-title">Sai Krishna</h5>
                                        <h6>(Advocate)</h6>
                                        <p className="card-text">BSSM Charitable trust is formed to advance support and help to the person who are below the poverty line or are from the deprived category. It is working for public benefit and welfare of all.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="card border-0 py-5">
                            <div className="block">
                                <div className="block">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Reviews%2FSrinivasa.jpg?alt=media&token=60d504f3-c906-42cd-8545-48eaa4dba556' className="img-fluid rounded-circle" width='150px' alt="..." />
                                </div>
                                <div className="block">
                                    <div className="card-body">
                                        <h5 className="card-title">Srinivasa Sharma</h5>
                                        <h6>(Ex.Service Man)</h6>
                                        <p className="card-text">It is an abode of peace, quiet and soulful energy. Amazingly positive place. Visit at least once a month and you'll be surprised by how your stress just subsides.</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="card border-0 py-5">
                            <div className="block">
                                <div className="block">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Reviews%2FMurthy.jpg?alt=media&token=5ea758b7-124f-4354-9776-6c0887d94825' className="img-fluid rounded-circle" width='150px' alt="..." />
                                </div>
                                <div className="block">
                                    <div className="card-body">
                                        <h5 className="card-title">GSN Murthy</h5>
                                        <h6>(Irrigation Engineer)</h6>
                                        <p className="card-text">BSSM Charitable trust is Well organised with service orientation among the devotees to serve the society. It takes you deep inside your hearts forgetting the entire world. Seva volunteers are at hand to help at all times</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="card border-0 py-5">
                            <div className="block">
                                <div className="block">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Reviews%2FRajeshwar.jpg?alt=media&token=c4a45f69-4821-4d35-9a78-6302f2eed30e' className="img-fluid rounded-circle" width='150px' alt="..." />
                                </div>
                                <div className="block">
                                    <div className="card-body">
                                        <h5 className="card-title">Kota Rajeshwar</h5>
                                        <h6>(Retd.Sanskrit Professor)</h6>
                                        <p className="card-text">Abode of Sri Sri Sri Sathyananda Bhaghavan Ashram, a pleasant & devotional atmosphere. Good Darshan, Bhajana Mandiram with devotional songs as per the occasion. charities keep our mind clean and peaceful.</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="card border-0 py-5">
                            <div className="block">
                                <div className="block">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Reviews%2FChandrakanth.jpg?alt=media&token=d96dee47-3304-494e-9733-beb5bca5995b' className="img-fluid rounded-circle" width='150px' alt="..." />
                                </div>
                                <div className="block">
                                    <div className="card-body">
                                        <h5 className="card-title">Chandrakanth</h5>
                                        <h6>(ADE, NPDCL)</h6>
                                        <p className="card-text">The BSSM charitable trust is made to provide services to senior citizens, orphans and physically disabled children. Volunteers of Sri Sathyananda charitable trust are active and participate in social service.</p>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="card border-0 py-5">
                            <div className="block">
                                <div className="block">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Reviews%2FKomuraiah.jpg?alt=media&token=d3b4b7a9-80e8-41e8-ae7d-2f63a020fdde' className="img-fluid rounded-circle" width='150px' alt="..." />
                                </div>
                                <div className="block">
                                    <div className="card-body">
                                        <h5 className="card-title">Komuraiah</h5>
                                        <h6>(ED, RTC)</h6>
                                        <p className="card-text">A trust dedicated to Bhagvan Sri Satyananda Charity of Bowenpally. A lot of social service activities are undertaken like personality development for children and Educational aids to the poor,Food distribution for the needy people etc conducted regularly.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}
