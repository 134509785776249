import React from 'react'
import Gallery from '../Gallery'
import { goseva } from './Activities'

export default function Goseva() {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Goseva</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FGoseva%2Fgomaata.jpg?alt=media&token=ab4f4776-dbef-42ec-9a64-bb232315c752' alt="" />
                    <div className='p-2'>
                       <p> India was previously home to 72 species of indigenous cows. After independence we lost over 65% of them. All that remain are 26 species.
                        The rest are either hybrid or cross breed. Sri Satyananda  maharshi is on a mission to preserve the native species and protect them from becoming extinct.</p>
                        <h5>Vedam:</h5>
                        <p> All beings in the entire universe depend on food. Food comes from rain. Rain comes from Yagna. Yagnas are rendered with Veda mantras and cows ghee.Through their tapascharya ascetism, pravrutti Cognition and Dyana Meditation, the ancient seers of India established harmony between physical sciences and the Vedic inscriptions. The present day educated people of India exhibit a stand-offish attitude to Vedic pronouncements. They opine that these theories were not arrived through experimentation but only by cognition. But, now the western scientists are coming into grips with the vitality of the Vedic sciences.</p>

                        <h5>Why Cow Protection?</h5>

                        <p> When the bull and cow are in a joyous mood, it can be construed that the people of the world are also in joyful mood.The human society must, therefore, protect these two most precious animals so they can wander about with no worries and with all cheer.</p>

                        <h5>Cow In Vedas:</h5>

                        <p>In the Vedas, Cow is called Aditi, Dhenuvu, Aghnaaya etc. ‘Cow is referred in the Rg Veda 723 times, in Yajurveda 87 times, In Sama Veda 170 times, in Athrava Veda 331 times-total 1331 times. Similarly 20 times in Rg Veda, 5 times in Yajurveda, 2 times in Sama veda and 33 times in Athrava veda the word Aghnaaya specifically addressed to cow. "Dhenu" is used 76 times in Rg Veda, 22 times in Yajurveda, 25 times in Sama Veda, 43 times in Atharva Veda. The meaning of Dhenu is which gives Trupti (Contentment and satisfaction).</p>

                        <h5>Kaama Dhenuvu -Cow is wish fulfilling sacred animal:</h5>

                        <div> There many uses of cow-
                        <ol>
                            <li>Milk</li>
                            <li>Cream3</li>
                            <li>Yogurt </li>
                            <li>Butter </li>
                            <li> Ghee</li>
                            <li> Residue after making yogurt</li>
                            <li> residue of the ghee (like crystals)they are called as Godavari</li>
                            <li>Butter milk </li>
                            <li>Cow urine as purifier and medicinal uses </li>
                            <li>Cow dung is scientifically proven to be antibiotic, anti- nuclear radiation and anti-septic and Manure is used as fertilizer  </li>
                            <li>Cow manure is used for Yagnas like Putra Kaameshti, Varshesti(Varsha Yagna) </li>
                            <li> The cow manure is used in preparing gober gas</li>
                            <li>The air pollution will be controlled by the smoke of the yagnas when cow dung cakes are being used in the fire.</li>
                            <li>Many sweets and milk products</li>
                        </ol>
                            <p> So every part of the cow’s body is being used for the welfare of the mankind. She is soft and revered animal. They are like our kids and friends. Wherever she is tied she will stay, wherever we send she will go. We have to protect them from cruelty.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <Gallery gallery={goseva} />
                </div>
            </div>
        </>
    )
}
