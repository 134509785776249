import React from 'react'
import { Link } from 'react-router-dom'
import { activities } from '../All-data'

export default function Mainbody() {
    return (
        <>
            <div className="album py-5">
                <div className="container-fluid">
                    <div>
                        <h3 className='mb-4 text-center text-decoration-underline'>Our Activities</h3>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 p-2  g-3">
                        {activities.map(activity => {
                            const { id, title, pic, path, text } = activity;
                            return (
                                <div className="col" key={id}>
                                    <div className="card shadow-sm">
                                        <img className='img-fluid mainimg' src={pic} alt="" />
                                        <div className="card-body">
                                            <h5 className="card-title">{title}</h5>
                                            <p className="card-text">{text}</p>
                                            <Link to={path} className="btn btn1">Know More...</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
