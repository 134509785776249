import React from 'react'
import Gallery from '../Gallery'
import { medical } from './Activities'

export default function Medical() {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Medical Camps</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='' alt="" />
                    <div className='p-2'>
                        <p> We conducted several medical camps to provide free medical advice and medicine to the unfortunate people and refer for specialized treatment or surgery whenever it is required. These camps make sure people are getting healthcare at the right time, and seeing the doctor early enough before a small health problem turns into severe disease.</p>
                        <p> Our Charitable Trust, and medical professional devotees  are driven by strong ethics of society who believe that it is the moral responsibility and obligation to treat each patient regardless of their income, race or social status. The main objective of a medical camp is to provide initial care to people in life-threatening conditions which reflect the unique strengths and goals of medical ethics. </p>
                    </div>
                </div>
                <div>
                    <Gallery gallery={medical} />
                </div>
            </div>
        </>
    )
}
