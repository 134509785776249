import React from 'react'
import Gallery from '../Gallery'
import { yoga } from './Activities'

export default function Yoga() {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Yoga and Meditation</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FYoga%2Fmeditation.jpg?alt=media&token=42a06d06-8f51-4386-8414-9318b427f4d9' alt="" />
                    <div className='p-2'>
                        <h5>What is the importance of yoga?</h5>

                        <p> The art of practicing yoga helps in controlling an individual's mind, body and soul. It brings together physical and mental disciplines to achieve a peaceful body and mind; it helps manage stress and anxiety and keeps you relaxing. It also helps in increasing flexibility, muscle strength and body tone.</p>

                        <h5>10 health benefits of Yoga:</h5>
                            <ol>
                            <li>Improves posture.</li>
                            <li>Increases flexibility.</li>
                            <li>Builds muscle strength.</li>
                            <li>Boosts metabolism.</li>
                            <li>Helps in lowering blood sugar.</li>
                            <li>Keep diseases at bay.</li>
                            <li>Increases self-esteem.</li>
                            </ol>
                        <p> Yoga is essentially a spiritual discipline based on an extremely subtle science, which focuses on bringing harmony between mind and body. It is an art and since of healthy living. The word 'Yoga' is derived from the Sanskrit root 'Yuj', meaning 'to join' or 'to yoke' or 'to unite'. </p>
                        <h5>Meditation Tips:</h5>
                        <ol>
                            <li>“In meditation, you must go beyond thought. As long as you are busy thinking, you are in your rational mind, on the conscious plane. When you sleep and dream, you are on the subconscious plane. And when your mind is fully withdrawn in super consciousness, it becomes centered in the bliss of the spine. That is the level of the soul’s existence.”</li>
                            <li>“Do not be anxious if you don’t have meditative experiences. The path to God is not a circus! Don’t even be anxious about such fruits of meditation as inner joy and peace. Everything will come in time. Meanwhile, consider meditation, too, as a form of karma yoga: action without desire for the fruits of action. Meditate above all to please your higher self, not your ego.”</li>
                            <li>Every sincere effort is registered in the Divine consciousness. Your duty…is to accept whatever He sends you — and, for that matter, He doesn’t send. God alone knows what past karma keeps you from perceiving Him right now. He may want you to finish up your karma in this life, before giving you eternal bliss.”</li>
                            <li>“Don’t waste the perception of the God’s presence, acquired in meditation, by useless chatting. Idle words are like bullets: they riddle the milk pail of peace. In devoting time unnecessarily to conversation and exuberant laughter, you’ll find you have nothing left inside. Fill the pail of your consciousness with the milk of meditative peace, then keep it filled. Joking is false happiness. Too much laughter riddles the mind and lets the peace in the bucket flow out, wasting it.”</li>
                            <li>“Meditate regularly, and you will find a joy inside that is real. You will then have something you can compare to sense pleasures. That comparison will automatically make you want to forsake your sorrow-producing bad habits. The best way to overcome temptation is to have something more fulfilling to compare it with.”</li>
                            <li>“Never count your faults. Just think about whether you love God enough. God doesn’t mind your faults, only your indifference.”</li>
                            <li>“Many people meditate till they feel a touch of peace, but jump up then and leave their meditation for their activities. That’s all right, if you have important work waiting for you, for it is always better to meditate before any activity, that you may feel at least some peace as you work. Whenever possible, however, sit for a long time after your practice of techniques. That is when the deepest enjoyment comes. Intuition is developed by continuously deepening that enjoyment, and, later on, by holding on to its calm aftereffect.”</li>
                            <li>“God answers all prayers. Restless prayers, however, He answers only a little bit. If you offer to others something that isn’t yours to give, won’t that be a merely empty gesture? If you pray…similarly, but lack control over your own thoughts, that prayer will be without power. Thoughts and feelings, both, must be focused when you pray. Otherwise God will meet your little trickle with another trickle in return! Answers will be doled out to you in a teaspoon. Too often, prayer is more like the halfhearted mumbling of a beggar than the confident, loving demand of a friend.”</li>
                            <li>“You won’t find God by making constant excuses: for example, saying, ‘When I find a quiet place, I will meditate.’ That is not at all the way to get there! If you tell yourself, however, “Right now I will plunge into deep meditation!” you can be there in a moment. When you are really sleepy, you have no difficulty in sleeping no matter where you are. When a person is in love, he or she finds no difficulty in thinking of the beloved; rather, it is difficult not to think of him or her, even to the point of ignoring work. Be in love with God! It is easy to meditate deeply, when your love for God is deep enough.”</li>
                        </ol>
                    </div>
                </div>
                <div>
                    <Gallery gallery={yoga} />
                </div>
            </div>
        </>
    )
}
