import React from 'react';

const Donate = () => {
    return (
        <>
            <div className="album py-5">
                <div className="container-fluid">
                    <div>
                        <h3 className='mb-4 text-center text-decoration-underline'>Our Account Details</h3>
                    </div>
                        <div className="col">
                            <div className="card p-1 shadow-sm">
                                <img className='mainimg' src='' alt="" />
                                    <div className="card-body">
                                    <h5 className="card-title">Bhagavan Sri Satyananda Maharshi Charitable Trust</h5>
                                        <p className="card-text">
                                            A/C No: 36403765558 <br />
                                            IFSC code: SBIN0011082 <br />
                                            Bank: State Bank of India <br />
                                            Branch: Kompally
                                        </p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Donate;
