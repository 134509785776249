
export default function Gallery({gallery}) {
    return (
        <>
            <div className="album py-5">
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 g-3">
                        {gallery.map(activity => {
                            const { id, pic } = activity;
                            return (
                                <div className="col" key={id}>
                                    <div className="card shadow-sm">
                                        <img className='card-img img-fluid shadow-sm' src={pic} alt="" />
                                        {/* <div className="card-body">
                                        <h5 className="card-title">{title}</h5>
                                        <p className="card-text">{text}</p>
                                        <Link to={path} className="btn btn1">Know More...</Link>
                                    </div> */}
                                    </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}