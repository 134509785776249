import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
    return (
        <div className='text-center m-3 p-3'>
            <h2 className='p-2'>No Page Found</h2> <br />
            <Link to='/'><button className='btn btn-primary btn-lg'><h3>Return to Home page</h3></button></Link>
        </div>
    )
}
