import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import Carousel from './Components/Layout/Carousel'
import Mainbody from './Components/Layout/Mainbody';
import Reviews from './Components/Layout/Reviews'


function App() {
  return (
    <>
    <Carousel />
    <Mainbody />
    <Reviews />
    </>
  );
}

export default App;
