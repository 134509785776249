import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App'
import Footer from './Components/Layout/Footer'
import Navbar from './Components/Layout/Navbar'
import About from './Components/Basic/About'
import Contact from './Components/Basic/Contact'
import Error from './Components/Basic/Error'
import Food from './Components/Activities/Food'
import Goseva from './Components/Activities/Goseva'
import Medical from './Components/Activities/Medical'
import Oldage from './Components/Activities/Oldage'
import Scholar from './Components/Activities/Scholar'
import Yoga from './Components/Activities/Yoga'
import Donate from './Components/Basic/Donate'
import Personality from './Components/Activities/Personality'


export default function Routes() {
    return (
        <>
            <Router>
                <Navbar />
                <Switch>
                    <Route exact path='/'>  <App /> </Route>
                    <Route path='/About'> <About /> </Route>
                    <Route path='/Donate'> <Donate /> </Route>
                    <Route path='/Contact'> <Contact /> </Route>
                    <Route path='/Help-to-Poor'> <Food /> </Route>
                    <Route path='/Medical-Camps'> <Medical /> </Route>
                    <Route path='/Yoga-Meditation'> <Yoga /> </Route>
                    <Route path='/Goseva'> <Goseva /> </Route>
                    <Route path='/Oldage-Home'> <Oldage /> </Route>
                    <Route path='/Scholarships'> <Scholar /> </Route>
                    <Route path='/Personality-Development'> <Personality /> </Route>

                    <Route path='*'> <Error /> </Route>
                </Switch>
                <Footer />
            </Router>
        </>
    )
}
