import React from 'react'

export default function About() {
    const url1 = 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Others%2Fline.svg?alt=media&token=7d5a1442-b198-43dc-959a-8ed9f1f12aee';
    const url2 ='https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Slides%2FBSSM-1.jpg?alt=media&token=9da8ddc1-6e88-494e-a6b5-0c67c5a2da13';
    return (
        <>
        <div className='container-fluid'>
            <div className='my-2'>
                <div className='card border-0'>
                    <h2 className='text-center m-3'>About Us</h2>
                    <img className='card-img m-auto' height='30px' src={url1} alt="" />
                </div>
                <div className='card mx-2 border-0'>
                        <img className='card-img img-fluid' src={url2} alt="" />
                        <div className='p-1'>
                            <p>To utilise the trust fund as well as the income accruing to the Trust fund after meeting all the costs, charges and expenses incurred in or about or incidental to the administration or execution of the trust for the following Public Charitable purposes: </p>
                           <p> a)	To advance Indian Culture and Literature, Services of this country for the benefit of our Nation <br />
                            b)	Relief of poor <br />
                            c)	Medical relief <br />
                            d)	Spread of education and <br />
                            e)	Advancement of any other subject of general public utility as cane be regarded as public charitable purpose and the benefits whereof are not restricted to any particular caste. Creed, religion or community and in such manner as the Trustees may think fit for one or more of such charitable purposes to the exclusion of other or others as the Trustees may think fit. <br />
                            f)	To establish centres of employment generation. <br />
                            g)	To confer title on eminent personality in the field of Science, Education, Literature,etc. <br />
                            h)	To establish Cultural and Social Institutions. <br />
                            i)	To establish maintain and run Schools, Colleges Social Service centres, Industrial Training Institutions. <br />
                            j)	To establish Training centre and allied education Institutions. <br />
                            k)	To under take  propaganda training and education of masses either of its own or in co-operation with similar agencies working for cause of all round development of the Society.</p>

                           <p>Without prejudice to the generality of the foregoing objects or purposes but subject as aforesaid:
                            It is declared that the
                            Trustees shall, each year apply the residue of the income of the Trust Fund and may at their discretion at any time and from time to time apply all the trust fund in or towards any one or more of the following objects or purposes to the execution of the other in such portion and manner in all respect as the Trustees may in their absolute discretion think proper. </p>

                           <p> a)	Establishing and maintaining or helping by endowment or otherwise orphanages or houses for the benefit of poor, sick, destitute, homeless, orphans, widows, aged, crippled, handicapped, disabled and/ or other deserving persons. <br />
                            b)	Establishing or rendering help to any institutions for the alleviation of human sufferings. <br />
                            c)	Distribution of free or subsidized food and clothing to the poor and needy. <br />
                            d)	Granting relief and assistance to the needy victims during natural calamities such as famine, earthquake, flood, fire, pestilence etc. and giving donations and other assistance to institutions. Establishments or persons engaged in such relief work. <br />
                            e)	Fostering children and promoting such schemes and projects which aim at the all-round development of children. <br />
                            f)	Promoting child welfare and poverty eradication programmes. <br />
                            g)	Granting, subscription, contribution and/ or donations to any public funds or institutions for promotion of any public charitable objects. <br />
                            h)	Spread or promotion of education or learning in all its branches in such manner as the Trustees may think fit including: <br /> <br />
                            i)  Establishment and/ or acquisition and maintenance or support or assistance in any other manner or schools, colleges, balamandirs, study and research centers, technical and other institutions, for imparting education and training. <br />
                            ii) Instituting scholarships and/ or endowments for the benefit of students. <br />
                            iii)	Providing assistance, financial or otherwise in cash or in kind to economically backward students without any distinction as to caste, creed or religion. <br />
                            iv)	Establishment and maintenance of and support of Hostels and/ or Boarding houses and grant of free or subsidized boarding/ lodging to poor and deserving students upon such terms and for such periods in each case as the Trustees may think fit. <br />
                            v)	Publishing and/ or granting aid in publishing books, magazines, reports, periodicals etc. to impart education, promote literature, arts and culture, promote vocational guidance, etc. </p>

                           <p> Affording medical relief in such manner as the Trustees may think fit, including: <br />
                            i)	 Establishment and maintenance of dispensaries, Convalescent Homes, Asylums, medical institutions, etc. for administering any medical relief to the needy upon such terms and conditions and fix such period as the trustees may think fit. <br />
                            ii)  Grant of medical to the poor.
                            </p>
                        
                        </div>
                </div>
            </div>
        </div>
        </>
    )
}
