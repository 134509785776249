export const food = [
    {
        id: 11,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-1.jpeg?alt=media&token=e052f618-4df7-4d36-af41-c73262cd87ee'
    },
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-4.jpeg?alt=media&token=36138894-c5c5-4cb0-a897-86b9f459cca9'
    },
    {
        id: 13,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-2.jpg?alt=media&token=04cd32f4-c522-4c67-8e17-253deae1ca3d'
    },
    {
        id: 14,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-5.jpg?alt=media&token=8ecdd878-b46f-4ce4-85d3-00195b23214d'
    },
    {
        id: 15,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-3.jpeg?alt=media&token=2b9cdd78-66fe-4224-bb3d-b177f6b9b31c'
    },
    {
        id: 16,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-6.jpeg?alt=media&token=bd39bbc7-301a-419b-adbf-a49dd01d5e93'
    },
    {
        id: 17,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FFood%2Ffood-7.jpeg?alt=media&token=faffc3ce-aa47-4fef-8193-fe461ff1751a'
    },
    {
        id:18,
        pic:'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FHelp%2FHelp-5.jpg?alt=media&token=8373ae2d-6950-47d5-9a64-4bbfe8c7a374'
    }
];
export const goseva = [
    {
        id: 11,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FGoseva%2Fgoseva-1.jpeg?alt=media&token=88979785-f43f-4476-a17c-67bf51d919c2'
    },
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FGoseva%2Fgoseva-2.jpg?alt=media&token=0266da5b-3399-44b8-adfe-50aa6afb3037'
    },
    {
        id: 13,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FGoseva%2Fgoseva-3.jpeg?alt=media&token=7bebec07-ed92-4314-8819-1ce1e1a2d6e8'
    },
];
export const medical = [
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FMedical%2Fmedical-2.jpg?alt=media&token=a37eecfd-8f91-4fc1-90da-09e67e8ec13a'
    },
    {
        id: 13,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FMedical%2Fmedical-3.jpg?alt=media&token=80adc06c-d389-487e-88f3-b24f7ab052a6'
    },
    {
        id: 14,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FMedical.jpg?alt=media&token=123f9680-4881-45e3-ac18-246421487010',
    },
    {
        id:15,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FMedical%2Fmedical-7.jpg?alt=media&token=644957eb-ec95-48ff-b22e-de66cbf5f9aa',
    },
];
export const oldage = [
    {
        id: 11,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FOld%2Fold-1.jpeg?alt=media&token=66e9541e-4cc0-489a-bc1e-662a4443f30e'
    },
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FOld%2Fold-2.jpeg?alt=media&token=48d38911-3bba-4bf3-8e2a-b20613e442b0'
    },
];
export const scholar = [
    {
        id: 11,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FScholarship%2Fschol-2.jpg?alt=media&token=3bd9eae9-6182-41dc-b7a6-c0802637f3c9'
    },
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FScholarship%2Fschol-4.jpeg?alt=media&token=38655f6d-43b0-4367-ae33-f53099a8cf10'
    },
    {
        id: 13,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FScholarship%2Fschol-3.jpg?alt=media&token=4de1d11a-1857-4cc7-af8f-e218e2ca025c'
    },
    {
        id: 14,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FScholarship%2FScholarship-6.jpg?alt=media&token=53361c91-97f3-402b-8751-35e70cfff120'
    }
];
export const yoga = [
    {
        id: 10,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FYoga%2Fyoga-1.jpg?alt=media&token=f6a8f47a-5b36-44db-8652-fe568239dc05'
    },
    {
        id: 11,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FYoga%2Fyoga-2.jpg?alt=media&token=8f71c007-e260-4cb5-ac90-f0159863d97e'
    },
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FYoga%2Fyoga-3.jpg?alt=media&token=9c749569-4938-4979-a9c6-3ec975cfff58'
    },
    {
        id: 13,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FYoga%2Fyoga-5.jpg?alt=media&token=c41437e2-e243-4578-a371-592d0c097b72'
    },
    {
        id: 14,
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FYoga%2Fyoga-4.jpg?alt=media&token=3276e164-698a-4922-817a-abc071f775b9'
    },
];

export const personality = [
    {
        id: 11,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FPersonality%2FPersonality-6.jpg?alt=media&token=71d717fb-1051-4e77-a0e8-b2c0f39960f0'
    },
    {
        id: 12,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FPersonality%2FPersonality-4.jpg?alt=media&token=4e57524b-b03d-4a94-bfc2-4ac8ec64d96c'
    },
    {
        id: 13,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FPersonality%2FPersonality-5.jpg?alt=media&token=6f20c231-8033-447c-9776-2b5f00ef846e'
    },
    {
        id: 14,
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FPersonality%2FPersonality-7.jpg?alt=media&token=728bbe16-1763-4d5e-bb22-8288901da721'
    },
];