import React from 'react'
import { oldage } from './Activities'
import Gallery from '../Gallery'

export default function Oldage() {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Sri Satyananda Oldage Ashramam</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='' alt="" />
                    <p className='p-2'>
                        There is an Old Age Home setup in Inamadugu and Kishan nagar where Elderly people who wish to spend their retired life peacefully can opt for staying in the old age home. Currently 18 rooms are available for stay in Inamadugu. Free food is provided to the inmates. Very nominal amount is taken for the stay if the inmates can contribute.
                    </p>
                </div>
                <div>
                    <Gallery gallery={oldage} />
                </div>
            </div>
        </>
    )
}
