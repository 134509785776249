
export const activities = [
    {
        id: 11,
        title: 'Help to Poor',
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FFood.jpg?alt=media&token=4e255868-cfe2-4cf1-a88a-81aa2d186444',
        text: 'Donating is the auspicious and sacred of all the charitable activities. It is considered as holy as is offering to God.',
        path: '/Help-to-Poor'
    },
    {
        id: 12,
        title: 'Medical Camps',
        pic: 'https://firebasestorage.googleapis.com/v0/b/sri-satyananda-ashram.appspot.com/o/Activities%2FMedical%2Fmedical-1.jpg?alt=media&token=2def2371-a7a7-4214-b036-f3a49c2da291',
        text: 'Health care is a right, not a privilege. Everyone should get minimum health care support when they need.',
        path: '/Medical-Camps'
    },
    {
        id: 13,
        title: 'Yoga and Meditation',
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FYoga.jpg?alt=media&token=c91d4cc8-df25-4d74-b684-5eda1d91adf1',
        text: 'Yoga is not a work-out it is a work-in. It brings together physical and mental disciplines to achieve peace of mind.',
        path: '/Yoga-Meditation'
    },
    {
        id: 14,
        title: 'Goshala',
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FGoseva.jpg?alt=media&token=f431bfa7-29c4-46e5-ae67-10f24ddbf184',
        text: 'The Cow is representative of divine & natural beneficence. It is believed that 33 crore gods & goddesses reside inside it.',
        path: '/Goseva'
    },
    {
        id: 15,
        title: 'Oldage Home',
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FOldage.jpg?alt=media&token=d9940b00-7aa8-4678-be14-19e3b4600617',
        text: 'The steady security in an old age home gives them protection from intruders and helps them live a safe and secure life.',
        path: '/Oldage-Home'
    },
    {
        id: 16,
        title: 'Scholarships',
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FScholar.jpeg?alt=media&token=d177edf7-c59b-47fd-afa7-705336f42692',
        text: 'Scholarships provide an opportunity for people to earn an education, without taking assistance from an outside source.',
        path: '/Scholarships'
    },
    {
        id: 17,
        title: 'Personality Development',
        pic: 'https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FPersonality%2FPersonality.jpg?alt=media&token=9f3ed46f-d547-4ab7-a25e-af181c9e0587',
        text: 'Personality development helps an individual to gain confidence & high self esteem and It have positive impact on one\'s communication skills.',
        path: '/Personality-Development'
    }
];

