import React from 'react';
import Gallery from '../Gallery';
import { personality } from './Activities';

const Personality = () => {
    return (
        <>
            <div className='my-2' >
                <div className='card border-0'>
                    <h3 className='text-center mt-3 text-decoration-underline mx-1'>Personality Development</h3>
                </div>
                <div className='card mx-2 border-0'>
                    <img className='card-img img-fluid p-2' src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Activities%2FPersonality%2FPersonality.jpg?alt=media&token=9f3ed46f-d547-4ab7-a25e-af181c9e0587' alt="" />
                    <div className='p-2'>
                        <p> Personality development encompasses the dynamic construction and deconstruction of integrative characteristics that distinguish an individual in terms of interpersonal behavioral traits. Indeed, personality development is ever-changing and subject to contextual factors and life-altering experiences. Personality development is also dimensional in description and subjective in nature. That is, personality development can be seen as a continuum varying in degrees of intensity and change. </p>
                       <p> 1) It is subjective in nature because its conceptualization is rooted in social norms of expected behavior, self-expression, and personal growth. <br />
                        2) The dominant viewpoint in personality psychology indicates that personality emerges early and continues to develop across one's lifespan. <br />
                        3) Adult personality traits are believed to have a basis in infant temperament, meaning that individual differences in disposition and behavior appear early in life, potentially before language of conscious self-representation develop. <br />
                        4) The Five Factor Model of personality maps onto the dimensions of childhood temperament. <br />
                        5) This suggests that individual differences in levels of the corresponding personality traits (neuroticism, extraversion, openness to experience, agreeableness, and conscientiousness) are present from young ages. </p>
                    </div>
                </div>
                <div>
                    <Gallery gallery={personality} />
                </div>
            </div>
        </>
    );
}

export default Personality;
