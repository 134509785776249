import React from 'react'

export default function Carousel() {
    return (
        <>
            <div id="carouselExamplelight" className="carousel carousel-light slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExamplelight" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExamplelight" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExamplelight" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExamplelight" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExamplelight" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExamplelight" data-bs-slide-to="5" aria-label="Slide 6"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2000">
                        <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Slides%2FSlide-1.jpg?alt=media&token=cc34b5a3-8f7b-4255-8e83-b930a9cbcad8' className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Slides%2FSlide-2.jpg?alt=media&token=63134e76-1280-476a-a0f8-0763169d00a8' className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Slides%2FSlide-3.jpg?alt=media&token=2b477077-4746-4d6b-a371-11427a9dc4d0' className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Slides%2FSlide-4.jpg?alt=media&token=cd4ae6b9-fada-4b1c-a62a-ccc19255fa92' className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Slides%2FSlide-5.jpg?alt=media&token=7bd569e2-4aaa-4e7b-a5f1-179d67503d1f' className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src='https://firebasestorage.googleapis.com/v0/b/bssm-trust.appspot.com/o/Slides%2FSlide-6.jpg?alt=media&token=c154917f-9845-4d8e-b61f-1e12f8099f80' className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExamplelight" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExamplelight" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}
